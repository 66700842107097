<template>
  <div>
    <div :id="'Modalprint_' + item._id" :ref="'Modalprint_' + item._id">
      <h2 class="text-xl font-bold mb-3 tracking-wider uppercase">
        {{ $t("RM EXPRESS GLOBAL") }}
      </h2>
      <!-- Profile tab -->
      <!-- <img style="width:20%;margin:10px;" alt="..." class="rounded-full align-middle border-none" :src="image"/> -->
      <div class="flex justify-between">
        <!-- <div class="w-full md:w-1/3">
                      <label class="text-black-700 block mb-1 text-xs uppercase tracking-wide" style="font-size: 0.65rem;line-height: 1.20rem;"><span class="font-bold">Expéditeur:</span> <span style="font-size: 0.65rem;line-height: 1.20rem;" class="text-black-800 mb-1 text-xs uppercase tracking-wide  font-bold"> rmExpress</span></label>
                  </div>  -->
        <!-- <div class="w-full md:w-1/3">
                      <label class="text-black-700 block mb-1 text-xs uppercase tracking-wide" 
                      style="font-size: 0.65rem;line-height: 1.20rem;"><span class="font-bold">
                      {{$t('phone')}}:</span> <span style="font-size: 0.65rem;line-height: 1.20rem;" 
                      class="text-black-800 mb-1 text-xs uppercase tracking-wide font-bold">{{item.phone}} 
                      </span></label>
                  </div>  -->
        <!-- <div class="w-full md:w-1/3">
                      <label class="text-black-700 block text-sm uppercase tracking-wide" style="font-size: 0.65rem;line-height: 1.20rem;"><span class="font-bold">{{$t('order_no')}}:</span> <span style="font-size: 0.65rem;line-height: 1.20rem;" class="text-black-800 text-xs uppercase tracking-wide font-bold">{{item.order.id}} </span></label>
                  </div>  -->
      </div>
      <div class="flex justify-between" style="margin-top: 3px">
        <div class="w-full md:w-1/3">
          <label class="text-black-700 block text-sm uppercase tracking-wide"
            style="font-size: 0.65rem; line-height: 1.2rem"><span class="font-bold">{{ $t("ID de la commande ")
              }}:</span>
            <span class="text-black-800 text-xs uppercase tracking-wide font-bold"
              style="font-size: 0.65rem; line-height: 1.2rem">{{ item.order.id }}
            </span></label>
        </div>
        <div class="w-full md:w-1/3">
          <label class="text-black-700 block text-sm uppercase tracking-wide"
            style="font-size: 0.65rem; line-height: 1.2rem"><span class="font-bold">{{ $t("Date de la commande")
              }}:</span>
            <span class="text-black-800 text-xs uppercase tracking-wide font-bold"
              style="font-size: 0.65rem; line-height: 1.2rem">{{ $moment(item.date).format("yyyy-MM-DD") }}
            </span></label>
        </div>
      </div>
      <div class="flex justify-between" style="margin-top: 3px">
        <div class="w-full md:w-1/3">
          <label class="text-black-700 block text-sm uppercase tracking-wide"
            style="font-size: 0.65rem; line-height: 1.2rem"><span class="font-bold">{{ $t("Numero du client ")
              }}:</span>
            <span class="text-black-800 text-xs uppercase tracking-wide font-bold"
              style="font-size: 0.65rem; line-height: 1.2rem">{{ item.order.customer.phone }}
            </span></label>
        </div>
        <div class="w-full md:w-1/3">
          <label class="text-black-700 block text-sm uppercase tracking-wide"
            style="font-size: 0.65rem; line-height: 1.2rem"><span class="font-bold">{{ $t("store") }}:</span>
            <span class="text-black-800 text-xs uppercase tracking-wide font-bold"
              style="font-size: 0.65rem; line-height: 1.2rem">{{ item.seller.company }}
            </span></label>
        </div>
      </div>
      <div class="flex justify-between" style="margin-top: 3px">
        <div class="w-full md:w-1/3">
          <label class="text-black-700 block text-sm uppercase tracking-wide"
            style="font-size: 0.65rem; line-height: 1.2rem"><span class="font-bold">{{ $t("Nom du client ") }}:</span>
            <span style="font-size: 0.65rem; line-height: 1.2rem"
              class="text-black-800 text-xs uppercase tracking-wide font-bold">{{ item.order.customer.fullName }}
            </span></label>
        </div>
        <div class="w-full md:w-1/3">
          <label class="text-black-700 block text-sm uppercase tracking-wide"
            style="font-size: 0.65rem; line-height: 1.2rem"><span class="font-bold">{{ $t("N° de poste") }}:</span>
            <span style="font-size: 0.65rem; line-height: 1.2rem"
              class="text-black-800 text-xs uppercase tracking-wide font-bold">{{
                item.orderObjet.teleConsultantConfirm
                  ? item.orderObjet.teleConsultantConfirm.fullName
                  : ""
              }}
            </span></label>
        </div>
      </div>
      <div class="flex justify-between" style="margin-top: 3px">
        <div class="w-full md:w-1/3">
          <label class="text-black-700 block text-xs uppercase tracking-wide"
            style="font-size: 0.65rem; line-height: 1.2rem"><span class="font-bold"> {{ $t("Adresse de livraison")
              }}:</span>
            <span style="font-size: 0.65rem; line-height: 1.2rem"
              class="text-black-800 text-xs uppercase tracking-wide font-bold">{{ item.address }}
            </span></label>
        </div>

        <div class="w-full md:w-1/3">
          <label class="text-black-700 block text-sm uppercase tracking-wide"
            style="font-size: 0.65rem; line-height: 1.2rem"><span class="font-bold">{{ $t("La ville ") }}:</span>
            <span class="text-black-800 text-xs uppercase tracking-wide font-bold"
              style="font-size: 0.65rem; line-height: 1.2rem">{{ item.order.customer.city }}
            </span></label>
        </div>
      </div>

      <div class="flex justify-between" style="margin-top: 3px">
        <div class="w-full md:w-1/3">
          <label class="text-black-700 block text-sm uppercase tracking-wide"
            style="font-size: 0.65rem; line-height: 1.2rem"><span class="font-bold">{{ $t("Justification") }}:</span>
            <span style="font-size: 0.65rem; line-height: 1.2rem"
              class="text-black-800 text-xs uppercase tracking-wide font-bold">
            </span></label>
        </div>
      </div>

      <!--<p class="size-m leading-5 font-bold text-black">
              {{$t('order_items')}} :
          </p>-->

      <table class="min-w-full" style="margin-top: 30px">
        <thead class="justify-between">
          <tr class="border border-black">
            <th class="px-2 text-left leading-4 text-black tracking-wider">
              <span class="text-black">#</span>
            </th>
            <th style="font-size: 0.65rem; line-height: 1.2rem"
              class="px-2 text-left text-xs leading-4 text-black tracking-wider">
              <span class="text-black">{{ $t("Nom du produit ") }}</span>
            </th>
            <th style="font-size: 0.65rem; line-height: 1.2rem"
              class="px-2 text-left text-xs leading-4 text-black tracking-wider">
              <span class="text-black">{{ $t("ID de produit") }}</span>
            </th>
            <th style="font-size: 0.65rem; line-height: 1.2rem"
              class="px-2 text-center text-xs leading-4 text-black tracking-wider">
              <span class="text-black">{{ $t("quantity") }}</span>
            </th>

            <th style="font-size: 0.65rem; line-height: 1.2rem"
              class="px-2 text-center text-xs leading-4 text-black tracking-wider">
              <span class="text-black">{{ $t("Prix") }}</span>
            </th>
          </tr>
        </thead>
        <tbody class="bg-white w-full">
          <tr v-for="(el, index) in item.order.details" :key="index" class="bg-white border border-black">
            <td class="px-2 whitespace-no-wrap">
              <span style="font-size: 0.65rem; line-height: 1.2rem"
                class="px-2 text-xs whitespace-no-wrap text-black">{{ index + 1 }}</span>
            </td>
            <td class="">
              <span style="
                  font-size: 0.65rem;
                  line-height: 1.2rem;
                  white-space: initial;
                " class="px-2 text-xs whitespace-no-wrap text-black font-bold">{{ el.product.name }}</span>
            </td>
            <td class="">
              <span style="
                  font-size: 0.65rem;
                  line-height: 1.2rem;
                  white-space: initial;
                " class="px-2 text-xs whitespace-no-wrap text-black font-bold">{{ el.product.id_v2 }}</span>
            </td>
            <td class="px-2 text-center whitespace-no-wrap text-black">
              <span class="size-m leading-5 font-bold">{{ el.quantity }}</span>
            </td>
            <td class="px-2 text-center whitespace-no-wrap text-black">
              <span class="size-m leading-5 font-bold">{{
                el.unitPrice.toFixed(2)
              }}</span>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="ml-auto w-full sm:w-2/4 lg:w-1/6" style="border-top: 1px solid #000; margin-top: 4px">
        <div class="flex justify-between">
          <div class="text-black text-right size-m flex-1 text-xs font-bold" style="font-size: 0.75rem">
            {{ $t("Montant à verser ") }}:
          </div>
          <div class="text-right" style="width: 40mm; margin-right: 5px">
            <div class="text-black text-xs font-bold" style="font-size: 0.75rem">
              {{ item.order.totalPrice.toFixed(2) }}
              <sup v-if="idWharhouse">{{ idWharhouse.currency }}</sup>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-center">
        <p>
          <span class="py-4 whitespace-no-wrap border-b text-xs leading-5" style="font-size: 0.75rem"><span
              v-if="item.autorise_colis">{{ $t("autorise_colis") }}</span><span v-else>{{ $t("not_autorise_colis")
              }}</span></span>
        </p>
      </div>
      <div class="text-center barcode-img delivrey">
        <vue-barcode :value="item.trackingNumber" tag="svg"></vue-barcode>
        <!--<p class="text-black-600"><img width="200" class="inline" :src="image" alt=""></p>-->
      </div>
      <div class="flex w-full received" style="padding: 5px">
        <!-- <span class="px-5 py-2 w-full border-black border text-black-500 rounded">RECEIVED BY:
                  <p><span class=" py-4 whitespace-no-wrap border-b text-blue-900 border-gray-500text-xs leading-5">Name and signature:</span></p>
              </span>              -->
      </div>
    </div>

    <!--shipp list livreur -->
    <div :id="'ModalprintList_' + item._id" class="shipp-print">
      <!-- Profile tab -->
      <tr class="bg-white border border-black">
        <td style="
            font-size: 10px;
            word-wrap: break-word;
            white-space: normal !important;
          " class="text-center whitespace-no-wrap text-black">
          {{ item.order.id }}
        </td>
        <td style="padding-left: 3px; padding-right: 3px" class="text-center whitespace-no-wrap text-black">
          <span style="font-size: 0.5rem; line-height: 0.7rem"
            class="text-xs overflow-visible whitespace-no-wrap text-black">{{ item.trackingNumber }}</span>
        </td>
        <td style="
            font-size: 10px;
            word-wrap: break-word;
            white-space: normal !important;
          " class="text-center whitespace-no-wrap text-black">
          {{ item.order.customer.fullName }}
        </td>
        <td style="
            font-size: 10px;
            word-wrap: break-word;
            white-space: normal !important;
          " class="text-center whitespace-no-wrap text-black">
          {{ item.order.customer.phone }}
        </td>
        <td style="
            font-size: 10px;
            word-wrap: break-word;
            white-space: normal !important;
          " class="text-center whitespace-no-wrap text-black">
          {{ item.order.customer.city }}
        </td>
        <td style="
            font-size: 10px;
            word-wrap: break-word;
            white-space: normal !important;
          " class="text-center whitespace-no-wrap text-black">
          {{ item.seller.fullName || item.seller.company }}
        </td>
        <td style="
            font-size: 10px;
            word-wrap: break-word;
            white-space: normal !important;
          " class="text-center whitespace-no-wrap text-black">
          {{ sumProductQuantity(item.order.details) }}
        </td>
        <td style="
            font-size: 10px;
            word-wrap: break-word;
            white-space: normal !important;
          " class="text-center whitespace-no-wrap text-black">
          {{ sumProduct(item.order.details) }}
        </td>
        <td style="font-size: 10px" class="text-center whitespace-no-wrap text-black">
          {{ item.order.totalPrice.toFixed(2) }}
          <sup v-if="idWharhouse">{{ idWharhouse.currency }}</sup>
        </td>
        <td style="
            font-size: 10px;
            border: 0;
            border-width: 0;
            word-wrap: break-word;
            display: inline-block;
            overflow-wrap: break-word;
            white-space: normal !important;
          " class="text-center whitespace-no-wrap text-black">
          {{ item.address }}
        </td>
        <td style="padding-left: 3px; padding-right: 3px" class="barcode-img text-center whitespace-no-wrap text-black">
          <!-- <vue-barcode  :value="item.trackingNumber" tag="img"></vue-barcode> -->
        </td>
      </tr>
    </div>
    <!--/shipp list courier -->
  </div>
</template>
<script>
import css from "@/assets/style.css";
import trbodytable from "@/views/shippings/tableProducts.vue";
import image from "@/assets/edco_logo.png";
export default {
  components: {
    trbodytable,
  },
  data() {
    return {
      dropdownPopoverShow: false,
      cssLink: css,
      image: "https://storeno.b-cdn.net/rmexpressglob/logo.png",
      usernameTeleconsul: "",
      expediteur: "",
    };
  },
  props: {
    config: { required: true, type: Object },
    item: { default: [] },
    idWharhouse: { type: Object },
  },
  computed: {
    formattedDate() {
      return this.$moment(this.item.date).format("yyyy-MM-DD HH:mm");
    },
  },
  async mounted() { },
  methods: {
    exceptNmaeProduct(text) {
      if (text && text.length > 60) text = text.substring(0, 60) + "...";
      return text;
    },
    QuantityTotalProductOrder(data) {
      var qte = 0;
      for (let i in data.order.details) {
        qte += data.order.details[i].quantity;
      }
      return qte;
    },
    async getStatusByName(name) {
      const result = await this.$server.get("status", { name: name });
      return result.content._id;
    },
    sumProduct(data) {
      let product = "";
      for (let i in data) {
        if (data[i].product && data[i].product.name)
          product += data[i].product.name;
        if (data.length - 1 != i) product += " ,";
      }
      return product;
    },
    sumProductQuantity(data) {
      let quantity = "";
      for (let i in data) {
        if (data[i].quantity && data[i].quantity)
          quantity += data[i].quantity;
        if (data.length - 1 != i) quantity += " ,";
      }
      return quantity;
    },
  },
};
</script>
