<template>
  <tr class="bg-white border border-black">
        <td class="px-1 whitespace-no-wrap text-black">
            <span style="font-size: 0.63rem;line-height: 1.00rem;" class="text-xs text-black">{{index+1}}</span>
        </td>
        <td class="px-1">
        <span  style="font-size: 0.63rem;line-height: 1.00rem;white-space: initial;" class="text-xs  whitespace-no-wrap text-black">{{product.product.name}}</span>
        </td>
        <td class="px-1 text-center whitespace-no-wrap text-black">
        <span style="font-size: 0.63rem;line-height: 1.00rem;" class="text-xs text-black">{{product.quantity}}</span>
        </td>
        <!--<td class="px-1 text-center whitespace-no-wrap text-black">
        <span style="font-size: 0.63rem;line-height: 1.00rem;" class="text-xs text-black">{{ product.unitPrice}}</span>
        </td>-->
        <td class="px-1 text-center whitespace-no-wrap text-black">
        <span style="font-size: 0.63rem;line-height: 1.00rem;" class="text-xs text-black">{{ product.unitPrice*product.quantity}}</span>
        </td>
    </tr> 
</template>
<script>
export default {
    props: {
     prod:Object,
     index:Number
 },
  data :function () {
    return {
       product: this.prod,
      }
    },
    computed: {
     execProductName() {
         if((this.product.product.name).length>15) return (this.product.product.name).substring(0, 10) + "...";
         else return this.product.product.name;
        
      }, 
    }, 
}
</script>